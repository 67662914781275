import React from "react";
import HomePage from "./Components/HomePage/View/HomePage";
import MenuPage from "./Components/MenuPage/Controller/MenuPage.controller";
import Subscribe from "./Components/Subscribe/Controller/Subscribe.controller";
import { withRouter, Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HolidayOffer } from "./Components/HolidayOffer/View/HolidayOffer.view";

function App(props) {
    let loc = document.location.pathname;
    if ((loc !== "/etlap") & (loc !== "/") & (loc !== "/subscribe") & (loc !== "/unnepi-ajanlat")) {
        document.location.pathname = "/";
    }
    const { t } = useTranslation();

    if (props.location.state && props.location.state === "SUCCESSFUL_SUBSCRIBE") {
        alert(t("SUCCESSFUL_SUBSCRIBE"));
        props.history.push("/", undefined);
    }
    return (
        <Switch>
            <Route path="/etlap" component={MenuPage} />
            <Route path="/subscribe" component={Subscribe} />
            <Route path="/unnepi-ajanlat" component={HolidayOffer} />
            <Route path="*" component={HomePage} />
        </Switch>
    );
}

export default withRouter(App);
