import React, { Component } from "react";
import DesktopNavBar from "../View/DesktopNavBar";
import MobileNavBar from "../View/MobileNavBar";
import { withRouter } from "react-router-dom";

class Navbar extends Component {
    render() {
        window.onscroll = function() {
            scrollFunction();
        };

        let menuItems_array = document.getElementsByClassName("menubar__item");

        const scrollFunction = () => {
            if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
                document.getElementsByClassName("menubar__logo")[0].classList.add("sticky-logo");
                document.getElementsByClassName("mobileMenu")[0].classList.add("sticky");
                document.getElementsByClassName("menubar")[0].classList.add("sticky");

                for (let i = 0; i < menuItems_array.length; i++) {
                    menuItems_array[i].classList.add("sticky-item");
                }
            } else {
                document.getElementsByClassName("menubar__logo")[0].classList.remove("sticky-logo");
                document.getElementsByClassName("mobileMenu")[0].classList.remove("sticky");
                document.getElementsByClassName("menubar")[0].classList.remove("sticky");

                for (let i = 0; i < menuItems_array.length; i++) {
                    menuItems_array[i].classList.remove("sticky-item");
                }
            }
        };

        return (
            <React.Fragment>
                <DesktopNavBar history={this.props.history} />
                <MobileNavBar history={this.props.history} />
            </React.Fragment>
        );
    }
}

export default withRouter(Navbar);
