import React from "react";
import Navbar from "../../NavBar/Controller/Navbar";
import Footer from "../../Footer/View/Footer";
import Menu1Front from "../../../Assets/Menu/fm_palma_dec_szoro_01_elolap.jpg";
import Menu1Back from "../../../Assets/Menu/fm_palma_dec_szoro_01_hatlap.jpg";
import Menu2Front from "../../../Assets/Menu/fm_palma_dec_szoro_02_elolap.jpg";
import Menu2Back from "../../../Assets/Menu/fm_palma_dec_szoro_02_hatlap.jpg";
import "./less/HolidayOffer.css";

export const HolidayOffer = (props) => {
    return (
        <div className="homePage holiday-offer">
            <div className="above">
                <Navbar />
            </div>
            <div className="under">
                <div className={"holiday-offer-container"}>
                    <img src={Menu1Front} alt="Szorolap 01 előlap" className={"menu-image"} />
                    <img src={Menu1Back} alt="Szorolap 01 hátlap" className={"menu-image"} />
                    <img src={Menu2Front} alt="Szorolap 02 előlap" className={"menu-image"} />
                    <img src={Menu2Back} alt="Szorolap 02 hátlap" className={"menu-image"} />
                </div>
                <Footer />
            </div>
        </div>
    );
};
