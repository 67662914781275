import React from "react";
import "./less/DesktopNavBar.css";
import Logo from "../../../Assets/palma_menu_logo.png";
import Cookie from "js-cookie";
import Separator from "../../../Assets/menu_separator.jpg";
import { useTranslation } from "react-i18next";
import { Lang } from "../../../i18n";
import "../../../Assets/icons/font/flaticon.css";

const DesktopNavBar = (props) => {
    const { t, i18n } = useTranslation();

    const changeLang = (newLang) => {
        i18n.changeLanguage(newLang);
        Cookie.set("lang", newLang, { expires: 6000 });
        Lang.lang = newLang;
    };

    React.useEffect(() => {
        const hashLink = props.history.location.hash.substring(1);
        scrollToElement(hashLink);
        // eslint-disable-next-line
    }, [props.history.location.pathname]);

    const redirectTo = (link, noTryAgain) => {
        props.history.push(link);

        const elementID = link.substring(2);
        if (elementID === "tlap") window.scroll(0, 0);

        scrollToElement(elementID);
    };

    const scrollToElement = (elementID) => {
        const el = document.getElementById(elementID);
        if (el) {
            el.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest"
            });
        }
    };

    return (
        <div className="desktopMenu">
            <div className="menubar">
                <div className="menubar__logo">
                    <img onClick={() => props.history.push("/")} src={Logo} alt="menu_logo" />
                </div>

                <div className="menubar__item">
                    <p>
                        <a
                            href="/#HETI_MENU"
                            onClick={(e) => {
                                e.preventDefault();
                                redirectTo("/#HETI_MENU");
                            }}
                        >
                            {t("weeklyTitle")}
                        </a>
                    </p>
                </div>

                <div className="menubar__separator">
                    <img src={Separator} alt="separator" />
                </div>

                <div className="menubar__item">
                    <p>
                        <a
                            href="/#RÓLUNK"
                            onClick={(e) => {
                                e.preventDefault();
                                redirectTo("/#RÓLUNK");
                            }}
                        >
                            {t("menuAboutUs")}
                        </a>
                    </p>
                </div>

                {/* <div className="menubar__separator">
                    <img src={Separator} alt="separator" />
                </div>

                <div className="menubar__item">
                    <p>
                        <a
                            href="/#ASZTALFOGLALAS"
                            onClick={(e) => {
                                e.preventDefault();
                                redirectTo("/#ASZTALFOGLALAS");
                            }}
                        >
                            {t("reservationTitle")}
                        </a>
                    </p>
                </div> */}

                {/* <div className="menubar__separator">
                    <img src={Separator} alt="separator" />
                </div> */}

                {/* <div className="menubar__item">
                    <p>
                        <a
                            href="/unnepi-ajanlat"
                            onClick={(e) => {
                                e.preventDefault();
                                redirectTo("/unnepi-ajanlat");
                            }}
                        >
                            {t("holidayOffer")}
                        </a>
                    </p>
                </div> */}

                <div className="menubar__separator">
                    <img src={Separator} alt="separator" />
                </div>

                <div className="menubar__item">
                    <p>
                        <a href="/etlap.pdf" target="_blank" rel="noreferrer noopener">
                            {t("menuOffer")}
                        </a>
                    </p>
                </div>

                {/* <div className="menubar__item">
                    <p>
                        <a href="/karacsony_2023.pdf" target="_blank" rel="noreferrer noopener">
                            {t("christmasOffer")}
                        </a>
                    </p>
                </div> */}

                {/* <div className="menubar__separator">
                    <img src={Separator} alt="separator" />
                </div> */}

                {/* <div className="menubar__item">
                    <p>
                        <a
                            href="/szilveszter_menu.jpg"
                            target={"_blank"}
                            rel={"noreferrer noopener"}
                        >
                            {t("newYearMenu")}
                        </a>
                    </p>
                </div> */}

                {/* <div className="menubar__order">
                    <a
                        className="order-button"
                        target="_blank"
                        href={`https://rendeles.palmaetterem.hu/${Lang.lang}`}
                        rel="noreferrer noopener"
                    >
                        {t("menuOnline")}
                    </a>
                </div> */}

                <div className="menubar__langSel">
                    <p>
                        <span onClick={() => changeLang("hu")}>HU</span> /
                        <span onClick={() => changeLang("en")}>EN</span>
                    </p>
                </div>

                <div className="menubar__toggleButton">
                    <i className="flaticon-menu" />
                </div>
            </div>
        </div>
    );
};

export default DesktopNavBar;
