import React, { Component } from "react";
import Meal1 from "../../../Assets/new/palma_burger.jpg";
import Meal2 from "../../../Assets/new/marhapofaporkolt.jpg";
import Meal3 from "../../../Assets/new/erdei_gomba_kremleves.jpg";
import Meal4 from "../../../Assets/new/lazac_fogas_dugo.jpg";
import Meal5 from "../../../Assets/new/pritott_thai_teszta.jpg";
import Meal6 from "../../../Assets/new/csirkepaprikas.jpg";
import Meal7 from "../../../Assets/new/sous_vide-szuzpecsenye.jpg";
import Meal8 from "../../../Assets/new/pisztacias_sajttorta.jpg";
import Meal9 from "../../../Assets/new/baconos_csirkerolad.jpg";
import Meal10 from "../../../Assets/new/feketeerdo_pite_2022.jpg";
import "../View/less/MealGallery.css";

class MealsGallery extends Component {
    state = {
        meals: [Meal1, Meal2, Meal3, Meal4, Meal5, Meal6, Meal7, Meal8, Meal9, Meal10]
    };
    render() {
        const meal_gallery = this.state.meals.map((data, i) => {
            return (
                <div key={i} className="meal">
                    <img src={data} alt="meal" />
                </div>
            );
        });
        return (
            <div className="meals-gallery">
                <div className="mealBox">{meal_gallery}</div>
            </div>
        );
    }
}

export default MealsGallery;
