import React from "react";
import ReactDOM from "react-dom";
import DefaultAxios from "axios";
import axios from "./Api/axios";
import "bootstrap/dist/css/bootstrap.min.css";
/*import 'bootstrap/dist/js/bootstrap.bundle.min';*/
import "./index.css";
import "./i18n.js";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

const url =
    document.location.origin === "http://localhost:3000"
        ? "http://localhost:2000"
        : document.location.origin;
axios.initAxios(url);

DefaultAxios.get(url + "/api/rest")
    .then((response) => response.data)
    .then((ENV_KEYS) => {
        axios.initAxios(ENV_KEYS.REST_API_URL);

        const app = (
            <BrowserRouter>
                <App />
            </BrowserRouter>
        );

        ReactDOM.render(app, document.getElementById("root"));
        serviceWorker.unregister();
    });