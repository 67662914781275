import React from "react";
import { useTranslation } from "react-i18next";
import SubscribeDAO from "../DAO/Subscribe.dao";
import View from "../View/Subscribe.view";
import qs from "qs";

const Subscribe = (props) => {
    const { t } = useTranslation();

    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [checked, setChecked] = React.useState(true);

    const formOnChange = (newValue) => {
        this.setState(newValue);
    };

    const submit = (e) => {
        e.preventDefault();
        if (verifyData()) {
            const data = {
                email: email,
                name: name,
                checked: checked
            };
            sendData(qs.stringify(data));
        }
    };

    const verifyData = () => {
        if (!name || !email) {
            alert(t("SUB_MISSING_FIELD_VALUES"));
            return false;
        }
        return true;
    };

    const sendData = (data) => {
        SubscribeDAO.subToNewsletters(data)
            .then(() => {
                props.history.push("/", "SUCCESSFUL_SUBSCRIBE");
            })
            .catch((error) => {
                if (error.response) {
                    alert(t(error.response.data));
                } else {
                    alert(t(error.message));
                }
            });
    };

    return (
        <View
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            checked={checked}
            setChecked={setChecked}
            submit={submit}
            formOnChange={formOnChange}
        />
    );
};

export default Subscribe;
