import React from "react";
import AboutImg3 from "../../../Assets/rolunk_kep_03.png";
import AboutImg2 from "../../../Assets/new/egeszben_sult_csulok.jpg";
import AboutImg1 from "../../../Assets/new/belszin_steak_erdei_gombas_mac_and_cheese.jpg";
import { useTranslation } from "react-i18next";
import "./less/About.css";

const About = (props) => {
    const { t } = useTranslation();

    return (
        <div className="about-container" id="RÓLUNK">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-xl-6">
                        <div className="left-side">
                            {props.width > 1221 ? <img src={AboutImg3} alt="rolunk-3" /> : null}
                        </div>
                    </div>
                    <div className="col-12 col-xl-6">
                        <div className="right-side">
                            <div className="right-side__images">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="aboutImg">
                                                <img src={AboutImg1} alt="rolunk-1" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="aboutImg">
                                                <img src={AboutImg2} alt="rolunk-2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="right-side__text">
                                <div className="about__banner">
                                    <p>{t("aboutUsTitle")}</p>
                                </div>
                                <div className="about__text">
                                    <p>{t("aboutUsText")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
