import React from "react";
import "./less/HomePage.css";
import Navbar from "../../NavBar/Controller/Navbar";
import AboutControl from "../../About/Controller/AboutControl";
import ChefOffer from "../../ChefOffer/View/ChefOffer";
import Reservation from "../../Reservation/Controller/Reservation";
import WeeklyMenu from "../../WeeklyMenu/Controller/WeeklyMenu";
import Footer from "../../Footer/View/Footer";

const HomePage = () => {
    return (
        <div className="homePage">
            <div className="above">
                <Navbar />
            </div>
            <div className="under">
                <AboutControl />
                <ChefOffer />
                <Reservation />
                <WeeklyMenu />
                <Footer />
            </div>
        </div>
    );
};

export default HomePage;
