import axios from "../../../Api/axios";

export default class SubscribeDAO {
    static base = "/api/newsletter";

    static async subToNewsletters(data) {
        const request = axios.getInstance().post(this.base, data);
        const response = await request;

        return response.data;
    }
}
