import React from "react";
import "./less/Subscribe.view.css";
import { useTranslation } from "react-i18next";
import logo from "../../../Assets/logo_large.png";
import android from "../../../Assets/google_play.png";
import ios from "../../../Assets/app_store.png";

const Subscribe = (props) => {
    const { t } = useTranslation();

    return (
        <div className="subscribe-view">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="subscribe-content">
                            <img className="subscribe-palma-logo" src={logo} alt="pálma logo" />
                            <h1 className="subscribe-content__title">{t("SubscribeTitle")}</h1>
                            <label htmlFor="subscribeNameInput">
                                {t("SubscribeNameInputLabel")}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                id="subscribeNameInput"
                                value={props.name}
                                onChange={(event) => props.setName(event.target.value)}
                            />
                            <label htmlFor="subscribeEmailInput">
                                {t("SubscribeEmailInputLabel")}
                            </label>
                            <input
                                type="text"
                                required
                                className="form-control"
                                placeholder=""
                                id="subscribeEmailInput"
                                value={props.email}
                                onChange={(event) => props.setEmail(event.target.value)}
                            />
                            <label>
                                <input
                                    type="checkbox"
                                    name="accept-subscribe"
                                    id="subscribeAcceptSubscriptionInput"
                                    checked={props.checked}
                                    onChange={(event) => props.setChecked(event.target.checked)}
                                    className="subscribe-checkbox"
                                />
                                <p className="subscribe-checkbox-text">
                                    {t("SubscribePageCheckboxText")}
                                </p>
                            </label>
                            <p onClick={props.submit} className="subscribe-submit-button">
                                {t("SubscribePageSubmitButton")}
                            </p>
                            <div className="download-app-container">
                                <a href="https://play.google.com/store/apps/details?id=com.oneminorder.palmarestaurant&hl=en">
                                    <img src={android} alt="download android app" />
                                </a>
                                <a href="https://apps.apple.com/us/app/pálma-étterem/id1482745018?l=hu&ls=1">
                                    <img src={ios} alt="download iOS app" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subscribe;
