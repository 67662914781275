import React from "react";
import About from "../View/About";

export default class AboutControl extends React.Component {
    state = {
        width: 0
    };

    updateDimensions = () => {
        let width = window.innerWidth;
        if (width < 1200) this.setState({ width, opened: false });
        else this.setState({ width });
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        return <About width={this.state.width} />;
    }
}
