import React, { Component } from "react";
import axios from "../../../Api/axios";
import WeeklyMenuView from "../View/WeeklyMenuView";
import { cloneDeep } from "lodash";
import "../View/less/WeeklyMenu.css";

class WeeklyMenu extends Component {
    state = {
        menuPrice: "",
        menu: [
            {
                day: "monday",
                date: "augusztus 5.",
                soup: "Frankfurti leves",
                mainCourse: "Rántott harcs frissen kevert salátával",
                dessert: "Csoki torta"
            },
            {
                day: "tuesday",
                date: "augusztus 6.",
                soup: "Zenge borsóleves vajas galuskával",
                mainCourse: "Fokhagymás karaj majonézes burgonyasalátával"
            },
            {
                day: "wednesday",
                date: "augusztus 7.",
                soup: "Burgonya krémleves",
                mainCourse: "Borzas bundás jércemell kukoricás rizzsel, tartárral",
                dessert: "Ízes palacsinta"
            },
            {
                day: "thursday",
                date: "augusztus 8.",
                soup: "Húsleves Fridattó módra",
                mainCourse: "Bakonyi sertésragu galuskával",
                dessert: "Almás lepény"
            },
            {
                day: "friday",
                date: "augusztus 9.",
                soup: "Jókai bableves",
                mainCourse: "Túrós csusza",
                dessert: "Fagylalt"
            }
        ],
        error: null
    };

    componentDidMount() {
        this.fetchWeeklyMenu();
    }

    fetchWeeklyMenu = () => {
        axios
            .getInstance()
            .get("/api/menu")
            .then((weeklyMenu) => {
                this.setWeeklyMenu(weeklyMenu.data.menuItems);
                this.setState({ menuPrice: weeklyMenu.data.price });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "failLoad" });
            });
    };

    setWeeklyMenu = (weeklyMenu) => {
        let menu = cloneDeep(this.state.menu);
        for (var day in menu) {
            if (weeklyMenu[day]) {
                menu[day].date = weeklyMenu[day].date;
                menu[day].soup = weeklyMenu[day].soup;
                menu[day].mainCourse = weeklyMenu[day].mainCourse;
                menu[day].dessert = weeklyMenu[day].dessert;
            }
        }
        this.setState({ menu });
    };

    render() {
        return (
            <WeeklyMenuView
                menu={this.state.menu}
                menuPrice={this.state.menuPrice}
                error={this.state.error}
            />
        );
    }
}

export default WeeklyMenu;
