import React, { useState, useEffect, useRef } from "react";
import { usePdf } from "react-pdf-js";
import Spinner from "./Spinner";
import { useTranslation } from "react-i18next";

const MyPdfViewer = () => {
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(null);

    const { t } = useTranslation();

    const renderPagination = (page, pages, canvas) => {
        if (!pages) {
            return null;
        }

        const getPreviousButton = () => {
            let classNames = ["pdf-navigator"];
            if (page === 1) classNames.push("disabled");
            return classNames.join(" ");
        };

        const getNextButton = () => {
            let classNames = ["pdf-navigator"];
            if (page === pages) classNames.push("disabled");

            return classNames.join(" ");
        };

        const left = (
            <button
                className={getPreviousButton()}
                disabled={page === 1}
                onClick={() => setPage(page - 1)}
            >
                {t("pdfNavLeft")}
            </button>
        );

        const right = (
            <button
                className={getNextButton()}
                onClick={() => setPage(page + 1)}
                disabled={page === pages}
            >
                {t("pdfNavRight")}
            </button>
        );

        return (
            <div className="pdf-menu--container">
                <button
                    className={`display-on-large-screen ${getPreviousButton()}`}
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                >
                    {t("pdfNavLeft")}
                </button>
                {canvas}
                <button
                    className={`display-on-large-screen ${getNextButton()}`}
                    disabled={page === pages}
                    onClick={() => setPage(page + 1)}
                >
                    {t("pdfNavRight")}
                </button>
                <div className="pdf-navigator-md">
                    {left}
                    {right}
                </div>
                <div className="pdf-navigator-sm">
                    {right}
                    {left}
                </div>
            </div>
        );
    };

    const canvasEl = useRef(null);

    const [loading, numPages] = usePdf({
        file: "/etlap.pdf",
        page,
        canvasEl,
        scale: 1.5
    });

    useEffect(() => {
        setPages(numPages);
    }, [numPages]);

    return (
        <div className="pdf-menu--container">
            {loading && <Spinner />}
            {renderPagination(page, pages, <canvas className="pdf-canvas" ref={canvasEl} />)}
        </div>
    );
};

export default MyPdfViewer;
