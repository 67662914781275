import React from "react";
import MenuPageView from "../View/MenuPage.view";
import NavBar from "../../NavBar/Controller/Navbar";

export default class MenuPage extends React.Component {
    render() {
        return (
            <>
                <NavBar />
                <MenuPageView />
            </>
        );
    }
}
