import React from "react";
import Cookie from "js-cookie";
import HeaderLogo from "../../../Assets/palma_menu_logo.png";
import { useTranslation } from "react-i18next";
import { Lang } from "../../../i18n";
import "../View/less/MobileNavBar.css";

const MobileNavBar = (props) => {
    const [toggle, setToggle] = React.useState(false);

    const { t, i18n } = useTranslation();

    const toggleHandler = () => {
        setToggle(!toggle);
    };

    const changeLang = (newLang) => {
        i18n.changeLanguage(newLang);
        Cookie.set("lang", newLang, { expires: 6000 });
        Lang.lang = newLang;
    };

    React.useEffect(() => {
        const hashLink = props.history.location.hash.substring(1);
        scrollToElement(hashLink);
        // eslint-disable-next-line
    }, [props.history.location.pathname]);

    const redirectTo = (link, noTryAgain) => {
        props.history.push(link);

        const elementID = link.substring(2);
        if (elementID === "tlap") window.scroll(0, 0);

        scrollToElement(elementID);
    };

    const scrollToElement = (elementID) => {
        const el = document.getElementById(elementID);
        if (el) {
            el.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest"
            });
        }
    };

    return (
        <div className="mobileMenu">
            <div className="mobileMenu__header">
                <div className="header-logo">
                    <img
                        onClick={() => props.history.push("/")}
                        src={HeaderLogo}
                        alt="heade_logo"
                    />
                </div>
                <div className="header__toggleButton" onClick={toggleHandler}>
                    <i className="flaticon-menu" />
                </div>
            </div>
            {toggle ? (
                <div className="mobile-menubar">
                    <div className="mobile-menubar__item">
                        <p>
                            <a
                                href="/#HETI_MENU"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setToggle(false);
                                    redirectTo("/#HETI_MENU");
                                }}
                            >
                                {t("weeklyTitle")}
                            </a>
                        </p>
                    </div>

                    <div className="mobile-menubar__item">
                        <p>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    setToggle(false);
                                    redirectTo("/#RÓLUNK");
                                }}
                                href="/#RÓLUNK"
                            >
                                {t("menuAboutUs")}
                            </a>
                        </p>
                    </div>

                    {/* <div className="mobile-menubar__item">
                        <p>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    setToggle(false);
                                    redirectTo("/#ASZTALFOGLALAS");
                                }}
                                href="/#ASZTALFOGLALAS"
                            >
                                {t("reservationTitle")}
                            </a>
                        </p>
                    </div> */}

                    {/* <div className="mobile-menubar__item">
                        <p>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    setToggle(false);
                                    redirectTo("/unnepi-ajanlat");
                                }}
                                href="/unnepi-ajanlat"
                            >
                                {t("holidayOffer")}
                            </a>
                        </p>
                    </div> */}

                    <div className="mobile-menubar__item">
                        <p>
                            <a href="/etlap.pdf" target="_blank" rel="noreferrer noopener">
                                {t("menuOffer")}
                            </a>
                        </p>
                    </div>

                    {/* <div className="mobile-menubar__item">
                        <p>
                            <a href="/karacsony_2023.pdf" target="_blank" rel="noreferrer noopener">
                                {t("christmasOffer")}
                            </a>
                        </p>
                    </div> */}

                    {/* <div className="mobile-menubar__item">
                        <p>
                            <a
                                href="/szilveszter_menu.jpg"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {t("newYearMenu")}
                            </a>
                        </p>
                    </div> */}

                    {/* <div className="mobile-menubar__order">
                        <a
                            className="order-button"
                            target="_blank"
                            href={`https://rendeles.palmaetterem.hu/${Lang.lang}`}
                            rel="noreferrer noopener"
                        >
                            {t("menuOnline")}
                        </a>
                    </div> */}

                    <div className="mobile-menubar__langSel">
                        <p>
                            <span onClick={() => changeLang("hu")}>HU</span> /
                            <span onClick={() => changeLang("en")}>EN</span>
                        </p>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default MobileNavBar;
