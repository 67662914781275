import React from "react";
import MyPdfViewer from "./PDF.view";
import Logo from "../../../Assets/palma_menu_logo.png";
import { Lang } from "../../../i18n";
import { useTranslation } from "react-i18next";
import "./Less/MenuPage.css";

const MenuPageView = () => {
    const { t } = useTranslation();
    return (
        <div className="menu-page--container">
            <div className="menu-page--pdf-container">
                <MyPdfViewer />
                <a href={`${document.location.origin}/api/menu/download`} className="download">
                    {t("pdfDownload")}
                </a>
            </div>
            <div className="menu-page--online-ordering">
                <div className="info-box">
                    <img src={Logo} alt="Palma" />
                    <p>{t("pdfOnlineOrder")}</p>
                    <a
                        className="order-button"
                        target="_blank"
                        href={`https://rendeles.palmaetterem.hu/${Lang.lang}`}
                        rel="noreferrer noopener"
                    >
                        {t("menuOnline")}
                    </a>
                </div>
            </div>
            <div className="menu-page--footer">
                {t("pdfFooter")} {new Date().getFullYear()}
            </div>
        </div>
    );
};

export default MenuPageView;
