import React from "react";
import MealsGallery from "../Controller/MealsGallery";
import { useTranslation } from "react-i18next";
import "./less/ChefOffer.css";

const ChefOffer = () => {
    const { t } = useTranslation();
    return (
        <div className="chefOffer" id="AJANLATUNK">
            <div className="header">
                <div className="header__content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-xl-6">
                                <div className="header__content__left-side">
                                    <div className="left-side__content">
                                        <div className="chefOffer__banner">
                                            <p>{t("chefTitle")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="header__content__right-side">
                                    <div className="chefOffer__text">
                                        <p>{t("chefText")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="meal-gallery">
                <MealsGallery />
            </div>
        </div>
    );
};

export default ChefOffer;
