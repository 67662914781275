import React from "react";
import "./Less/Spinner.css";

const Spinner = () => (
    <div className="spinner">
        <div className="lds-dual-ring"></div>
    </div>
);

export default Spinner;
