import React, { Component } from "react";
import { cloneDeep } from "lodash";
import ReservationView from "../View/Reservation.view";
import ReservationForm from "../Static/ReservationForm";
import axios from "../../../Api/axios";
import qs from "qs";
import "../View/less/Reservation.css";

class Reservation extends Component {
    state = {
        form: cloneDeep(ReservationForm),
        isverified: false
        // captchaKey: ""
    };

    onChange = (event) => {
        let form = cloneDeep(this.state.form);
        form[event.target.id].value = event.target.value;
        this.setState({ form });
    };

    // onCaptchaChange = (value) => {
    //     this.setState({
    //         isverified: true,
    //         captchaKey: value
    //     });
    // }

    // expiredChallenge = () => {
    //     this.setState({
    //         isverified: false,
    //         captchaKey: ""
    //     });
    // }

    checkFormData = () => {
        const form = this.state.form;

        for (var key in form) {
            if (!form[key].value && form[key].required) {
                alert("Minden mező kitöltése kötelező! (All fields are required.)");
                return false;
            }
        }
        return true;
    };

    submitDataToAPI = () => {
        const form = this.state.form;
        const data = {
            name: form.name.value,
            tel: form.tel.value,
            email: form.email.value,
            location: form.location.value.value,
            date: form.date.value,
            time: form.time.value,
            personCount: form.personCount.value
            // captchaKey: this.state.captchaKey
        };
        axios
            .getInstance()
            .post("/api/reserve", qs.stringify(data))
            .then(() => {
                this.setState({ showAlert: true });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    submitButtonHandler = () => {
        // if (!this.state.isverified) {
        //     alert("Kérlek erősítsd meg hogy nem vagy robot!");
        // } else
        if (this.checkFormData()) {
            this.submitDataToAPI();
        }
    };

    render() {
        return (
            <ReservationView
                {...this.state}
                {...this.props}
                onChange={this.onChange}
                setAlert={() => this.setState({ showAlert: false })}
                onCaptchaChange={this.onCaptchaChange}
                expiredChallenge={this.expiredChallenge}
                submitButtonHandler={this.submitButtonHandler}
            />
        );
    }
}
export default Reservation;
